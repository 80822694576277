import axios from "axios"

const digitalAssets = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
})

// { query: '{}', data: null, total: 1 }
export interface checkOwnershipByID {
  query: any
  data: any
  total: number
}

export const checkOwnershipByID = ({ productID }: { productID: string }): Promise<checkOwnershipByID> => {
  return new Promise((resolve, reject) => {
    digitalAssets
      .get(`/api/fc/byproduct/${productID}`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}
