import { isOwner } from "./../../../views/DesignEditor/utils/checkOwner"
// import { Page } from "~/interfaces/common";
import { createAction, createAsyncThunk } from "@reduxjs/toolkit"
export interface IAuthenticationState {
  isAuthenticated?: boolean
  user?: any
  token?: string | null
}

export interface IIsOwner {
  isOwner: boolean
  isDirty?: boolean
}

export const setOwner = createAction<IIsOwner>("auth/setOwner")

export const getOwner = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  "auth/getOwner",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const isOwnerVar = await isOwner()
      dispatch(setOwner({ isOwner: isOwnerVar, isDirty: true }))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)

export const setAuth = createAction<IAuthenticationState>("auth/setAuth")
// export const removePage = createAction<Partial<Page>>(
//   "designEditor/removePage"
// );
