import { FontItem } from "~/interfaces/common"
import { IScene, ILayer, IStaticText } from "@layerhub-io/types"
import FontName from "fontname";
import { fontFamily } from "@mui/system";

const getFontsFromObjects = (objects: Partial<ILayer>[]) => {
  let fonts: any[] = []
  for (const object of objects) {
    if (object.type === "StaticText" || object.type === "DynamicText") {
      fonts.push({
        name: (object as Required<IStaticText>).fontFamily,
        url: (object as Required<IStaticText>).fontURL,
      })
    }
    if (object.type === "Group") {
      // @ts-ignore
      let groupFonts = getFontsFromObjects(object.objects)

      fonts = fonts.concat(groupFonts)
    }
  }
  return fonts
}

export const loadTemplateFonts = async (design: IScene) => {
  const fonts = getFontsFromObjects(design.layers)
  if (fonts.length > 0) {
    await loadFonts(fonts)
  }
}

export const loadFonts = (fonts: FontItem[]) => {
  const promisesList = fonts.map((font) => {
    return new FontFace(font.name, `url(${font.url})`).load()
  })

  return Promise.allSettled(promisesList).then((results) => {
    let allFontsLoaded = true

    results.forEach((result) => {
      if (result.status === "fulfilled") {
        document.fonts.add(result.value)
      } else {
        console.error("Font loading error:", result.reason)
        allFontsLoaded = false
      }
    })

    if (allFontsLoaded) {
      return true
    } else {
      console.error("Some fonts failed to load")
      return true
    }
  })
}

export  const getFontMeta = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = async (e: any) => {
        const buffer = e.target.result // ArrayBuffer
        let fontMeta

        try {
          fontMeta = FontName.parse(buffer)[0]
          resolve(fontMeta)
        } catch (error) {
          console.error("Error parsing font file", error)
          resolve({
            fontFamily: "Unknown",
            fullName: "Unknown",
            postScriptName: "Unknown",

          })
        }
      }
      reader.readAsArrayBuffer(file)
    })
  }

