import { Dispatch, SetStateAction } from "react"
import gif from "/gif/ezgif.com-video-to-gif.gif"
import React from "react"

export default function AltKey({
  isAltKeyPopup,
  setIsAltKeyPopup,
}: {
  isAltKeyPopup: boolean
  setIsAltKeyPopup: Dispatch<SetStateAction<boolean>>
}) {
  const localStorageKey = "doNotShowAgainAltKeyPopup"
  const [doNotShowAgain, setDoNotShowAgain] = React.useState(false)

  if (!isAltKeyPopup) return null

  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      {/* <!--
    Background backdrop, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {/* <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      --> */}
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-green-600"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg font-medium leading-6 text-gray-900" id="modal-title">
                    Use the Alt key to move the page around
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Press and hold the Alt key to move the page around when zoomed in.
                    </p>
                  </div>
                </div>
              </div>
              <img src={gif} alt="alt key" className="mt-8" />
            </div>
            <div className="bg-gray-50 px-4 py-3 flex flex-row md:flex-row justify-between sm:px-6">
              <div>
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="true"
                  onChange={(e) => {
                    setDoNotShowAgain(e.target.checked)
                    // console.log(e.target.checked)
                    // localStorage.setItem("altKeyPopup", "false")
                  }}
                />
                <label htmlFor="vehicle1"> Don't show this message again</label>
              </div>

              {/* <button
                type="button"
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Deactivate
              </button> */}
              <button
                type="button"
                onClick={() => {
                  if (doNotShowAgain) {
                    localStorage.setItem(localStorageKey, "true")
                  }

                  setIsAltKeyPopup(false)
                }}
                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
