import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { googleAuthenticate } from "~/services/auth";
import { authSelector } from "~/store/slices/auth/selectors";

interface OneTapSigninOptions {
  parentContainerId?: string;
}

const useOneTapSignin = (
  opt?: OneTapSigninOptions | null,
  delay: number = 1000 // Add a delay parameter (default is 1 second)
) => {
  const isAuthenticated = useSelector(authSelector).isAuthenticated;

  const { parentContainerId } = opt || {};
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // If the user is already signed in or loading, do nothing
    if (isAuthenticated) return;

    const timeoutId = setTimeout(() => {
      const { google } = window as any;
      if (google) {
        google.accounts.id.initialize({
          client_id: "616821027524-4s83i1r2qf5hcuaplpluerjopep8tr3k.apps.googleusercontent.com",
          callback: async (response: any) => {
            setIsLoading(true)
            // Sign in with the token provided by Google
            // await signIn("googleonetap", {
            //   credential: response.credential,
            //   redirect: true,
            //   ...opt,
            // });

            const res = await googleAuthenticate(response.credential)
            if (res.token) {
              sessionStorage.setItem("token", res.token)
              window.location.href = "/"
            } else {
              console.log("Something went wrong")
            }

            setIsLoading(false)
          },
          prompt_parent_id: parentContainerId,
          cancel_on_tap_outside: true,
          itp_support: true,
          style: "position: absolute; top: 100px; right: 30px;width: 0; height: 0; z-index: 1001;",
        })

        google.accounts.id.prompt((notification: any) => {
          if (notification.isNotDisplayed()) {
            console.log(notification.getNotDisplayedReason());
          } else if (notification.isSkippedMoment()) {
            console.log(notification.getSkippedReason());
          } else if (notification.isDismissedMoment()) {
            console.log(notification.getDismissedReason());
          }
        });
      }
    }, delay); // Delay before initializing the One Tap Sign-in

    // Cleanup timeout if the component is unmounted before the timeout completes
    return () => clearTimeout(timeoutId);
  }, [isLoading, isAuthenticated, parentContainerId, delay, opt]);

  return { isLoading };
};

export default useOneTapSignin;
