import { getSelectedProject } from "~/services/digital-assets"
import { isOwner } from "./../../../views/DesignEditor/utils/checkOwner"
import { createAction, createAsyncThunk } from "@reduxjs/toolkit"

// const ColoringBookProject = mongoose.Schema(
//   {
//     owner: {
//       type: mongoose.Schema.Types.ObjectId,
//       ref: "Account",
//       required: true,
//     },
//     project_name: {
//       type: String,
//       required: true,
//       default: "Untitled Project",
//     },
//     project_settings: {
//       // json object
//       type: Object,
//       default: {},
//     },
//     is_selected: {
//       type: Boolean,
//       default: false,
//     },
//     last_updated_at: {
//       type: Date,
//       default: Date.now,
//       required: true,
//     },
//   },
//   {
//     timestamps: true,
//   }
// )

export interface IProjectState {
  _id: string
  id: string
  owner: string
  project_name: string
  project_settings: Record<string, any>
  is_selected: boolean
  last_updated_at: string

  createdAt: string
  updatedAt: string
}


export const setProject = createAction<IProjectState>("auth/setProject")

export const getProject = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  "auth/getProject",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const token = sessionStorage.getItem("token")
      if (!token) {
        return
      }
      const project = await getSelectedProject({ token })
      dispatch(setProject(project.data))
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)
