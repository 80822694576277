import React, { useEffect } from "react"
import { Input } from "baseui/input"
import { Block } from "baseui/block"
import CloudCheck from "~/components/Icons/CloudCheck"
import { StatefulTooltip } from "baseui/tooltip"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import useDesignEditorPages from "~/hooks/useDesignEditorScenes"
import { useEditor } from "@layerhub-io/react"
import { syncDataByUserId } from "~/services/digital-assets"
import { useDispatch, useSelector } from "react-redux"
import { openSnackBar } from "~/store/slices/snackbar/actions"
import { Editor } from "@layerhub-io/core"
import { IScene } from "@layerhub-io/types"
import { AnyAction, Dispatch } from "redux"
import { selectedProject } from "~/store/slices/project/selectors"

interface State {
  name: string
  width: number
}

const DesignTitle = () => {
  const project = useSelector(selectedProject)
  const [state, setState] = React.useState<State>({ name: project.project_name, width: 0 })
  const { currentDesign, setCurrentDesign } = useDesignEditorContext()
  const inputTitleRef = React.useRef<Input>(null)
  const spanRef = React.useRef<HTMLDivElement | null>(null)

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const handleInputChange = (name: string) => {
    setState({ ...state, name: name, width: spanRef.current?.clientWidth! })
    setCurrentDesign({ ...currentDesign, name })
  }

  React.useEffect(() => {
    const name = currentDesign.name
    if (name || name === "") {
      spanRef.current!.innerHTML = name
      setState({ ...state, name: name, width: spanRef.current?.clientWidth! + 20 })
    }
  }, [currentDesign.name])

  React.useEffect(() => {
    setState({ ...state, width: spanRef.current?.clientWidth! + 20 })
  }, [state.name])
  const syncData = syncDesignData(setIsLoading)

  useEffect(() => {
    setState({...state, name: project.project_name})
  } , [project.project_name])

  return (
    <Block
      $style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#ffffff",
        opacity: 1,
      }}
    >
      <Block $style={{ display: "flex", position: "absolute", top: "-10px", left: "50%", width: "100%" }}>
        <Block
          $style={{
            fontFamily: "Uber Move Text",
            position: "absolute",
            top: "-10px",
            left: "50%",
            fontSize: "14px",
            fontWeight: 500,
          }}
          ref={spanRef}
        >
          {state.name}
        </Block>
      </Block>
      <Block width={`${state.width}px`} display="flex">
        <Input
          onChange={(e: any) => handleInputChange(e.target.value)}
          overrides={{
            Root: {
              style: {
                backgroundColor: "transparent",
                borderTopStyle: "none",
                borderBottomStyle: "none",
                borderRightStyle: "none",
                borderLeftStyle: "none",
              },
            },
            InputContainer: {
              style: {
                backgroundColor: "transparent",
                paddingRight: 0,
              },
            },
            Input: {
              style: {
                fontWeight: 500,
                fontSize: "14px",
                width: `${state.width}px`,
                fontFamily: "",
                backgroundColor: "transparent",
                color: "#ffffff",
                paddingRight: 0,
              },
            },
          }}
          value={state.name}
          ref={inputTitleRef}
        />
      </Block>

      <StatefulTooltip
        showArrow={true}
        overrides={{
          Inner: {
            style: {
              backgroundColor: "#ffffff",
            },
          },
        }}
        content={() => (
          <Block backgroundColor="#ffffff" color="black">
            Save All Changes
          </Block>
        )}
      >
        <Block
          className={`${isLoading ? "animate-bounce" : ""}`}
          onClick={syncData}
          $style={{
            cursor: "pointer",
            padding: "10px",
            display: "flex",
            color: "#ffffff",
          }}
        >
          <CloudCheck size={24} />
        </Block>
      </StatefulTooltip>

      {/* new project */}
    </Block>
  )
}

export default DesignTitle

export function syncDesignData(
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const editor = useEditor()

  const dispatch = useDispatch()
  const scenes = useDesignEditorPages()
  const project_id = useSelector(selectedProject).id

  return () => {
    const token = sessionStorage.getItem("token")
    if (!token) {
      return
    }

    const updatedTemplate = editor.scene.exportToJSON()
    setIsLoading(true)
    const updatedPages = scenes
      .map((p) => {
        if (p.id === updatedTemplate.id) {
          return { ...updatedTemplate, preview: "" }
        }
        return p
      })
      .map((p) => {
        p.layers.forEach((l: any) => {
          if (l.type === "StaticImage" && l.hasOwnProperty("src") && l.src.startsWith("data:image")) {
            delete l.src
            delete l.preview
          }
        })
        const { ["preview"]: _, ...rest } = p

        return rest
      })

    syncDataByUserId({
      token,
      data: updatedPages,
      project_id,
    })
      .then((res) => {
        if (res.data.data) {
          dispatch(
            openSnackBar({
              title: "Success",
              message: "Scenes synced successfully",
              KIND: "success",
              timeout: 2000,
            })
          )
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(
          openSnackBar({
            title: "Error syncing scenes",
            message: err.message || "Something went wrong",
            KIND: "error",
            timeout: 2000,
          })
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
}
