import { BrowserRouter, Routes, Route } from "react-router-dom"
import DesignEditor from "~/views/DesignEditor"
import Dashboard from "~/views/Dashboard"
import Login from "~/views/auth/login"
import TestPage from "~/views/test-page"

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/manage" element={<Dashboard />} /> */}
        <Route path="/" element={<DesignEditor />} />
        <Route path="/login" element={<Login />} />
        <Route path={`/test-page`} element={<TestPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
