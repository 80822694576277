import { Typography } from "@mui/material"
import { sections, SectionItem, Section } from "./data"

function Menu2() {
  return (
    <div className={` h-fit br-16 rounded-md w-[80vw] `}>
      <div className="w-full flex flex-row  ">
        <div className=" flex br-16 flex-row justify-evenly">
          {sections.map((section: Section, ind) => {
            return (
              <div
                className={`w-full flex  px-4 py-8 flex-col ${
                  ind == 4 ? "bg-[#bfadff] pr-4 br-16" : ind == 0 ? "pl-4 br-16" : ""
                }`}
                key={ind}
              >
                <div className={`w-full text-2xl flex   justify-start pl-2 p-1 font-bold `}>
                  <img
                    // @ts-ignore
                    src={section.icon}
                    alt="what"
                    className="w-7 h-7"
                  />
                  <Typography variant="h6" className="ml-2 text-secCol1-700">
                    {section.name}
                  </Typography>
                </div>
                <div className="w-full  mr-2 flex flex-col flex-wrap">
                  {section.items.map((item: SectionItem, index) => {
                    return (
                      <a
                        href={item.productLink}
                        target={item.openInNewTab ? "_blank" : "_self"}
                        className={` w-full flex p-3 flex-col  cursor-pointer br-16 transition-all ${
                          ind == 4 ? "hover:bg-[#ab95fc]" : "hover:bg-gray-200"
                        }`}
                        key={index}
                      >
                        <div className="w-full  text-base text-black font-semibold capitalize ">
                          <Typography fontWeight={550} className="text-[16px]">
                            {item.item}
                          </Typography>
                        </div>
                        <div className=" text-gray-700 w-full ">
                          <Typography className="text-[14px]">{item.tag}</Typography>
                        </div>
                      </a>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Menu2
