import { getTextProperties } from "./../views/DesignEditor/utils/text"
export const BASE_ITEMS = [
  {
    id: "search",
    name: "Search",
  },
  {
    id: "categories",
    name: "Categories",
  },

  // {
  //   id: "elements",
  //   name: "Elements",
  // },
  // {
  //   id: "images",
  //   name: "Images",
  // },
  {
    id: "uploads",
    name: "Uploads",
  },
  {
    id: "text",
    name: "Text",
  },
  // {
  //   id: "font_selector",
  //   name: "FontSelector",
  // },
  {
    id: "draw",
    name: "Draw",
  },
  {
    id: "pageSizes",
    name: "PageSizes",
  },

  // {
  //   id: "eraser",
  //   name: "Eraser",
  // },
  // {
  //   id: "canvas_fill",
  //   name: "CanvasFill",
  // },

  // {
  //   id: "text_fill",
  //   name: "TestEffects",
  // },
  // {
  //   id: "graphics",
  //   name: "PathFill",
  // },
  // {
  //   id: "graphics",
  //   name: "Graphics",
  // },

  // {
  //   id: "layers",
  //   name: "Layers",
  // },
]

// export const VIDEO_PANEL_ITEMS = [
//   {
//     id: "templates",
//     name: "Templates",
//   },
//   {
//     id: "customize",
//     name: "Customize",
//   },
//   {
//     id: "elements",
//     name: "Elements",
//   },
//   {
//     id: "images",
//     name: "Images",
//   },
//   {
//     id: "videos",
//     name: "Videos",
//   },
//   {
//     id: "uploads",
//     name: "Uploads",
//   },
//   {
//     id: "text",
//     name: "Text",
//   },
//   {
//     id: "graphics",
//     name: "Graphics",
//   },

//   {
//     id: "pixabay",
//     name: "Pixabay",
//   },
// ]

export enum PanelType {
  SEARCH = "Search",
  BACKGROUND = "Background",
}
