import React, { useEffect, useState } from "react"
import { useEditor } from "@layerhub-io/react"
import { Block } from "baseui/block"
import Scrollable from "~/components/Scrollable"
import InfiniteScrolling from "~/components/InfiniteScrolling"
import { IStaticImage } from "@layerhub-io/types"
import Search from "~/components/Icons/Search"
import { Input } from "baseui/input"
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component"
import { SIZE, Spinner } from "baseui/spinner"
import api from "~/services/api"
import AngleDoubleLeft from "~/components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import { getAllDigitalAssetsImages, getBase64ImageFromURL } from "~/services/digital-assets"
import { v4 as uuidv4 } from "uuid"
import Fuse from "fuse.js"
import { useDispatch, useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"
import { url } from "inspector"

const tokenize = (text: string): string[] => {
  return text.toLowerCase().match(/[a-zA-Z]+/g) || []
}

const calculateTF = (tokenizedDoc: string[], term: string): number => {
  let count = 0
  tokenizedDoc.forEach((word) => {
    if (word === term) {
      count++
    }
  })
  return count / tokenizedDoc.length
}

const calculateIDF = (documents: string[], term: string): number => {
  let docsWithTerm = 0
  documents.forEach((doc) => {
    if (tokenize(doc).includes(term)) {
      docsWithTerm++
    }
  })
  return docsWithTerm === 0 ? 0 : Math.log(documents.length / docsWithTerm)
}

const calculateTFIDF = (document: string, documents: string[], term: string): number => {
  const tokenizedDoc = tokenize(document)
  const tf = calculateTF(tokenizedDoc, term)
  const idf = calculateIDF(documents, term)
  return tf * idf
}

const NaturalSearch = (query: string, documents: string[]): { document: string; score: number }[] => {
  const scores: { document: string; score: number }[] = []
  query = query.toLowerCase()
  documents.forEach((doc) => {
    const score = calculateTFIDF(doc, documents, query)
    console.log("score", score)
    scores.push({ document: doc, score })
  })
  return scores.sort((a, b) => b.score - a.score)
}

function SortObjects(obj1: any, obj2: any) {
  let scoreMap = new Map(obj2.map((obj: any) => [obj.document, obj.score]))

  obj1.sort((a: any, b: any) => {
    let scoreA = scoreMap.get(a.filename) || 0
    let scoreB = scoreMap.get(b.filename) || 0
    // @ts-ignore
    return scoreB - scoreA
  })

  return obj1
}

const SearchComponent = () => {
  const editor = useEditor()
  const [hasMore, setHasMore] = React.useState(true)
  const [images, setImages] = useState<any[]>([])
  const [filteredImage, setFilteredImage] = useState<any[]>([])
  const [allImages, setAllImages] = useState<any[]>([])
  const [pageNumber, setPageNumber] = React.useState(1)
  const [isLoading, setIsLoading] = React.useState(true)
  const [searchKey, setSearchKey] = useState<string>("")
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const spaceBaseUrl = "https://booksbytitans-bucket.sgp1.digitaloceanspaces.com"
  const perPage = 12

  const [subTotalImages, setSubTotalImages] = React.useState(0)

  const isOwner = useSelector(isOwnerSelector).isOwner

  const addObject = React.useCallback(
    (url: string) => {
      if (editor) {
        if (!isOwner) {
          alert("To add images to the canvas, one needs to purchase the Coloring Book Maker tool first.")
          return
        }

        const id = uuidv4()
        const finalUrl = spaceBaseUrl + "/coloring-book-maker-assets-public" + url
        const options = {
          id: id,
          type: "StaticImage",
          src: finalUrl,
        }
        editor.objects.add(options).then(() => {
          // editor.objects.resize("height", 200, "test")
          // editor.objects.resize("width", 200, "test")
          editor.objects.scale("fit", id)
        })

        // getBase64ImageFromURL(url)
        //   .then((data) => {

        //   })
        //   .catch((err) => {
        //   })
      }
    },
    [editor, isOwner]
  )

  useEffect(() => {
    if (searchKey === "") {
      setHasMore(true)
      setImages(allImages.slice(0, perPage))
      setSubTotalImages(perPage)
      setFilteredImage([])
    }
  }, [searchKey])

  const addMore = React.useCallback(() => {
    if (allImages.length === 0) return
    if (filteredImage.length) {
      if (filteredImage.length > images.length) {
        setIsLoading(true)
        const currentTotal = subTotalImages + perPage
        setSubTotalImages(currentTotal)
        setImages(filteredImage.slice(0, currentTotal))
        setIsLoading(false)
      } else {
        setHasMore(false)
      }
    } else {
      if (allImages.length > images.length) {
        setIsLoading(true)
        const currentTotal = subTotalImages + perPage
        setSubTotalImages(currentTotal)
        setImages(allImages.slice(0, currentTotal))
        setIsLoading(false)
      } else {
        setHasMore(false)
      }
    }
  }, [hasMore, allImages, images])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const all = await getAllDigitalAssetsImages()
      const options = {
        // isCaseSensitive: false,
        // includeScore: false,
        // shouldSort: true,
        // includeMatches: false,
        // findAllMatches: false,
        // minMatchCharLength: 1,
        // location: 0,
        // threshold: 0.6,
        // distance: 100,
        // useExtendedSearch: false,
        // ignoreLocation: false,
        // ignoreFieldNorm: false,
        keys: ["filename"],
      }

      const fuse = new Fuse(all, options)
      const filteredData = fuse.search("dinosaur").map((item) => item.item)

      const data = [...new Set([...filteredData, ...all])]

      setSubTotalImages(perPage)
      setAllImages(data)

      setImages(data.slice(0, perPage))
      setIsLoading(false)
    })()
  }, [])

  const makeSearch = () => {
    if (searchKey.length < 2) return
    setImages([])
    // const filteredData = allImages.filter(function (obj) {
    //   return obj["filename"].search(new RegExp(category, "i")) !== -1
    // })
    const minLength = searchKey.length

    const options = {
      // isCaseSensitive: false,
      // includeScore: false,
      // shouldSort: true,
      // includeMatches: false,
      // findAllMatches: false,
      minMatchCharLength: minLength,
      // location: 0,
      // threshold: 0.6,
      // distance: 100,
      // useExtendedSearch: false,
      // ignoreLocation: false,
      // ignoreFieldNorm: false,
      keys: ["filename"],
    }

    // console.log(search(searchKey, fileNames))

    const fuse = new Fuse(allImages, options)
    const filteredData = fuse.search(searchKey).map((item) => item.item)
    // const filteredData = allImages
    //   .filter(function (obj) {
    //     const filename = obj["filename"].toLowerCase().replace(".jpg", "").replace(".png", "")
    //     return filename === category.toLowerCase() || filename + "s" === category.toLowerCase()
    //   })
    //   .concat(
    //     allImages.filter(function (obj) {
    //       const filename = obj["filename"].toLowerCase().replace(".jpg", "").replace(".png", "")
    //       return filename !== category.toLowerCase() && filename.includes(category.toLowerCase())
    //     })
    //   )

    if (filteredData.length === 0) {
      setHasMore(false)
      setSubTotalImages(0)
      setFilteredImage([])
      setImages([])
    } else {
      setHasMore(true)
      setSubTotalImages(perPage)

      const filteredFileNames = filteredData.map((item) => item.filename)
      let searchTerm = searchKey
      searchTerm.toLowerCase()
      const sortedData = SortObjects(filteredData, NaturalSearch(searchTerm, filteredFileNames))
      setFilteredImage(sortedData)
      setImages(sortedData.slice(0, perPage))
    }

    // setPageNumber(1)
    // setIsloading(true)
    // fetchData(true)
  }
  return (
    <Block flex={1} flexDirection="column" display={"flex"}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem 1.5rem 0",
        }}
      >
        <Block>Images</Block>

        <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
          <AngleDoubleLeft size={18} />
        </Block>
      </Block>

      <Block $style={{ padding: "1.5rem 1.5rem 1rem" }}>
        <Input
          overrides={{
            Root: {
              style: {
                paddingLeft: "8px",
              },
            },
          }}
          onKeyDown={(key) => key.code === "Enter" && makeSearch()}
          onBlur={makeSearch}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Search"
          size={"compact"}
          endEnhancer={
            <div className="cursor-pointer shadow-2xl">
              <Search size={16} />
            </div>
          }
        />
      </Block>
      <Scrollable>
        <Block padding={"0 1.5rem"}>
          <InfiniteScrolling fetchData={addMore} hasMore={hasMore}>
            <Block
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "0.5rem",
              }}
            >
              {images.map((image) => {
                const previewURL = spaceBaseUrl + "/coloring-book-maker-preview/preview" + "/" + image.preview
                const imageUrl = image.fpath + "/" + image.filename
                return (
                  <Block
                    $style={{ cursor: "pointer" }}
                    className="h-full w-full"
                    onClick={() => addObject(imageUrl)}
                    key={image.id}
                  >
                    {/* <LazyLoadImage url={imageUrl} /> */}
                    <LazyLoadImage
                      // style={{
                      //   transform: "translateZ(0)",
                      // }}
                      // translate="no"
                      className="aspect-square h-20 w-full object-contain"
                      alt={image.filename}
                      effect="blur"
                      src={previewURL}
                      onContextMenu={(e) => {
                        e.preventDefault()
                      }}
                    />
                  </Block>
                )
              })}
              {images.length === 0 && !isLoading && (
                <Block
                  $style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  No images found
                </Block>
              )}
            </Block>
            <Block
              $style={{
                display: "flex",
                justifyContent: "center",
                paddingY: "2rem",
              }}
            >
              {isLoading && <Spinner $size={SIZE.small} />}
            </Block>
          </InfiniteScrolling>
        </Block>
      </Scrollable>
    </Block>
  )
}

export default trackWindowScroll(SearchComponent)
