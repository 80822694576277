import axios from "axios"

const api = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
})

export interface ILoginResponse {
  token?: string
  Error?: string
}

export interface ILogin {
  username: string
  password: string
}

// https://api.selfpublishingtitans.com/api/account/login
// {
//     token: string
// }
// Method: POST
export const login = ({ username, password }: ILogin): Promise<ILoginResponse> => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/account/login`, {
        username,
        password,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}


// /api/auth/google [post]
export async function googleAuthenticate(token: string): Promise<ILoginResponse> {
  // const res = await fetch(
  //   process.env.NEXT_PUBLIC_GO_API_URL + "/api/auth/google",
  //   {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify({ token }),
  //   }
  // );
  return new Promise((resolve, reject) => {
    api
      .post(`/api/auth/google`, {
        token,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((err) => reject(err))
  })
}