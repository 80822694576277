import React from "react"
import Search from "~/components/Icons/Search"
import { Input, SIZE } from "baseui/input"
import useAppContext from "~/hooks/useAppContext"
import { useStyletron } from "baseui"
import { useEditor } from "@layerhub-io/react"
import { getFontMeta, loadFonts } from "~/utils/fonts"
import { groupBy, set } from "lodash"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"
import { Delete } from "baseui/icon"
import { useSelector } from "react-redux"
import { selectFonts } from "~/store/slices/fonts/selectors"
import { useAppDispatch } from "~/store/store"
import { queryFonts } from "~/store/slices/fonts/actions"
import InfiniteScrolling from "~/components/InfiniteScrolling"
import { useDebounce } from "use-debounce"
import DropZone from "~/components/Dropzone"
import { toBase64 } from "~/utils/data"
import { nanoid } from "nanoid"
import { Button } from "baseui/button"
import { closeSnackBar, openSnackBar } from "~/store/slices/snackbar/actions"
import { uploadedFontsClassDB } from "~/indexDB/db"
import { listFiles, upload as uploadFont } from "~/services/cloud-service"

export default function () {
  const [hasMore, setHasMore] = React.useState(true)
  const [pageNumber, setPageNumber] = React.useState(1)
  const [query, setQuery] = React.useState("")
  const { setActiveSubMenu } = useAppContext()
  const fonts = useSelector(selectFonts)
  const [commonFonts, setCommonFonts] = React.useState<any[]>([])
  const [searchQuery] = useDebounce(query, 250)
  const [css] = useStyletron()
  const editor = useEditor()
  const dispatch = useAppDispatch()
  const [uploads, setUploads] = React.useState<any[]>([])
  const baseUrl = import.meta.env.VITE_R2_COLORING_BOOKS_FONT_URL;
  const [usage, setUsage] = React.useState<any>(0)
  const token = sessionStorage.getItem("token");

  const fileUrlToFile = async (url: string) => {
    const response = await fetch(url)
    const data = await response.blob()
    const metadata = {
      type: "application/octet-stream",
    }
    return new File([data], "font", metadata)
  }

  const initFonts = async () => {
    if (!token) return;
    let uploadedFonts;
    try {
      // get local uploaded fonts (legacy code)
      uploadedFonts = await uploadedFontsClassDB.fonts.get(1)
    } catch (error: any) {
      console.error(error)
      dispatch(
        openSnackBar({
          title: "Error",
          message: error.message,
          KIND: "error",
          timeout: 5000,
        })
      )
    }

    // get cloud uploaded fonts
    try {
      const allFonts = await listFiles("coloring-books-fonts", token)
      if (allFonts?.data?.data?.length ?? 0) {
      let fonts = []
      for (let i = 0; i < allFonts.data.data.length; i++) {
        const file = allFonts.data.data[i]
        const url = `${baseUrl}/${file.file_path}`;
        const fontBlob = await fileUrlToFile(url)
        const fontMeta: any = await getFontMeta(fontBlob)
        const font = {
          id: nanoid(),
          name: fontMeta.fontFamily,
          family: fontMeta.fontFamily,
          full_name: fontMeta.fullName,
          post_script_name: fontMeta.postScriptName,
          preview: "",
          style: "normal",
          // fontFamily: fontMeta.fontFamily,
          url: url,
          category: "none",
        }
        fonts.push(font)
      }
      if (uploadedFonts) {
        setUploads([...uploadedFonts.fonts, ...fonts])
      } else {
        setUploads([...fonts])
      }
    }
    } catch (error: any) {
      console.error(error)
      dispatch(
        openSnackBar({
          title: "Error",
          message: error.message,
          KIND: "error",
          timeout: 5000,
        })
      )
    }
    
  }
  React.useEffect(() => {
    const grouped = groupBy(fonts, "family")
    const standardFonts = Object.keys(grouped).map((key) => {
      const familyFonts = grouped[key]
      const standardFont = familyFonts.find((familyFont: any) => familyFont.post_script_name.includes("-Regular"))
      if (standardFont) {
        return standardFont
      }
      return familyFonts[familyFonts.length - 1]
    })
    setCommonFonts(standardFonts)
    initFonts()
  }, [fonts])

  const handleFontFamilyChange = async (x: any) => {
    if (editor) {
      const font = {
        name: x.post_script_name,
        url: x.url,
      }

      await loadFonts([font])

      editor.objects.update({
        fontFamily: x.post_script_name,
        fontURL: font.url,
      })
    }
  }

  React.useEffect(() => {
    dispatch(
      queryFonts({
        query: searchQuery,
        skip: pageNumber,
        take: 100,
      })
    )
    setHasMore(false)
    if (!searchQuery) {
      setHasMore(true)
    } else {
      setHasMore(false)
    }
  }, [searchQuery])

  const fetchData = React.useCallback(() => {
    if (!searchQuery) {
      dispatch(
        queryFonts({
          query: searchQuery,
          skip: pageNumber,
          take: 100,
        })
      )
    }

    setPageNumber(pageNumber + 1)
  }, [pageNumber, searchQuery])

  const inputFileRef = React.useRef<HTMLInputElement>(null)

  const handleInputFileRefClick = () => {
    inputFileRef.current?.click()
  }

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleDropFiles(e.target.files!)
  }

  const handleDropFiles = async (files: FileList) => {
    if (!token) return;
    if (files.length === 0) return;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // upload file to the server
      try {
        const res = await uploadFont(file, "coloring-books-fonts", token)
        const url = `${baseUrl}/${res.data.data.file_path}`;
        const fontMeta: any = await getFontMeta(file);
        const upload: any = {
          id: nanoid(),
          name: fontMeta.fontFamily,
          family: fontMeta.fontFamily,
          full_name: fontMeta.fullName,
          post_script_name: fontMeta.postScriptName,
          preview: "",
          style: "normal",
          // fontFamily: fontMeta.fontFamily,
          url: url,
          category: "none",
        }

        setUploads([...uploads, upload])
      } catch (error: any) {
        if(error?.response?.data?.error ?? 0) {
          dispatch(openSnackBar({ title: "Error", message: error.response.data.error, KIND: "error", timeout: 5000}))
        } else {
          dispatch(openSnackBar({ title: "Error", message: "Error uploading font", KIND: "error", timeout: 5000}))
        }
      } 
    }
   
  }

  const deleteAllFonts = () => {
    if (editor) {
      // update current font
      // delete all fonts
      // update current state
      handleFontFamilyChange({
        id: "font_GnsQL5DFYrLLAFwOOnmaIwt6",
        family: "Roboto",
        full_name: "Roboto Regular",
        post_script_name: "Roboto-Regular",
        preview: "https://ik.imagekit.io/scenify/fonts/previews/FzQ8nT_dmynGs1_VUqM27zAr.png",
        style: "Roboto-Regular",
        url: "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf",
        category: "sans-serif",
      })
      uploadedFontsClassDB.fonts
        .clear()
        .then(() => {
          setUploads([])
          dispatch(
            openSnackBar({ title: "Successfully deleted", message: "All fonts deleted successfully", KIND: "success", timeout: 5000})
          )
        })
        .catch((e) => {
          dispatch(openSnackBar({ title: "Error", message: "Error deleting font", KIND: "error", timeout: 5000}))
          console.error(e)
        })
    }
  }

  return (
    <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          fontWeight: 500,
          justifyContent: "space-between",
          padding: "1.5rem",
        }}
      >
        <Block>Select a font</Block>

        <Block onClick={() => setActiveSubMenu("")} $style={{ cursor: "pointer", display: "flex" }}>
          <Delete size={24} />
        </Block>
      </Block>
      <Block
        $style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: "0 1.5rem",
        }}
      >
        <Button
          onClick={handleInputFileRefClick}
          size={SIZE.compact}
          overrides={{
            Root: {
              style: {
                width: "100%",
                marginBottom: "0.5rem",
              },
            },
          }}
        >
          Upload Font
        </Button>

        <input
          onChange={handleFileInput}
          accept=".ttf,.otf"
          type="file"
          id="file"
          ref={inputFileRef}
          style={{ display: "none" }}
        />
        {/* <Button
          onClick={deleteAllFonts}
          size={SIZE.compact}
          disabled={uploads.length === 0}
          overrides={{
            Root: {
              style: {
                backgroundColor: "red",
                width: "100%",

                marginBottom: "0.5rem",
              },
            },
          }}
        >
          Delete All Fonts
        </Button> */}
      </Block>

      {uploads.length > 0 && (
        <Block
          $style={{
            padding: "1.5rem 1.5rem",
            display: "grid",
            gap: "0.2rem",

            maxHeight: "30vh",
          }}
        >
          <h1 className="text-xl">Uploaded Fonts</h1>

          <div className="overflow-y-auto  scrollbar-thin scrollbar-thumb-secCol1-300">
            {uploads.map((upload, idx) => {
              return (
                <div
                  key={upload.id + idx}
                  onClick={() => handleFontFamilyChange(upload)}
                  className={css({
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    fontSize: "14px",
                    ":hover": {
                      backgroundColor: "rgb(245,246,247)",
                    },
                  })}
                  id={upload.id}
                >
                  {/* <img src={upload.name} /> */}
                  <h1
                    style={{
                      fontFamily: upload.family,
                      fontWeight: 500,
                      fontSize: "14px",
                      margin: 0,
                      marginLeft: "0.5rem",
                    }}
                  >
                    {upload.name}
                  </h1>
                  {/* <LazyLoadImage url={font.preview} /> */}
                </div>
              )
            })}
          </div>
        </Block>
      )}

      <Block $style={{ padding: "0 1.5rem 1rem" }}>
        <Input
          overrides={{
            Root: {
              style: {
                paddingLeft: "8px",
              },
            },
          }}
          clearable
          onChange={(e) => setQuery((e.target as any).value)}
          placeholder="Search font"
          size={SIZE.compact}
          startEnhancer={<Search size={16} />}
        />
      </Block>

      <Scrollable>
        <Block $style={{ padding: "0 1.5rem", display: "grid", gap: "0.2rem" }}>
          <InfiniteScrolling fetchData={fetchData} hasMore={hasMore}>
            <Block $style={{ display: "grid" }}>
              {commonFonts.map((font, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleFontFamilyChange(font)}
                    className={css({
                      height: "40px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      fontSize: "14px",
                      ":hover": {
                        backgroundColor: "rgb(245,246,247)",
                      },
                    })}
                    id={font.id}
                  >
                    <h1
                      style={{
                        fontFamily: font.family,
                        fontWeight: 500,
                        fontSize: "14px",
                        margin: 0,
                        marginLeft: "0.5rem",
                      }}
                    >
                      {font.family}
                    </h1>
                    {/* {font.preview === "" ? (
                      <h1
                        style={{
                          fontFamily: font.family,
                          fontWeight: 500,
                          fontSize: "14px",
                          margin: 0,
                          marginLeft: "0.5rem",
                        }}
                      >
                        {font.name}
                      </h1>
                    ) : (
                      <img src={font.preview} />
                    )} */}
                    {/* <img src={font.preview} /> */}
                    {/* <LazyLoadImage url={font.preview} /> */}
                  </div>
                )
              })}
            </Block>
          </InfiniteScrolling>
        </Block>
      </Scrollable>
      {/* <Block $style={{ padding: " 1.5rem", display: "grid", gap: "0.2rem" }} className="bg-black text-white">
        Upload your own font
      </Block> */}
    </Block>
  )
}
