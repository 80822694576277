import { useStyletron, styled } from "baseui"
import { BASE_ITEMS } from "~/constants/app-options"
import useAppContext from "~/hooks/useAppContext"
import Icons from "~/components/Icons"
import { useTranslation } from "react-i18next"
import useSetIsSidebarOpen from "~/hooks/useSetIsSidebarOpen"
import useEditorType from "~/hooks/useEditorType"
import Scrollable from "~/components/Scrollable"
import { Block } from "baseui/block"
import DownloadIcon from "@mui/icons-material/Download"
import { Typography } from "@mui/material"
import { authSelector, isOwnerSelector } from "~/store/slices/auth/selectors"
import { useDispatch, useSelector } from "react-redux"
import jsPDF from "jspdf"
import { useEditor } from "@layerhub-io/react"
import { setLoadingStatus } from "~/store/slices/loading-status/actions"
import { selectFrameSize } from "~/store/slices/current-frame-size/selectors"
import useDesignEditorContext from "~/hooks/useDesignEditorContext"
import { Button } from "@mui/material"
import DesignTitle from "../Navbar/DesignTitle"
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks"

const Container = styled("div", (props) => ({
  width: "80px",

  display: "flex",
}))

const PanelsList = () => {
  const { activePanel } = useAppContext()
  const { t } = useTranslation("editor")
  const isOwner = useSelector(isOwnerSelector).isOwner
  const dispatch = useDispatch()
  const editorType = useEditorType()
  const editor = useEditor()
  const currentSize = useSelector(selectFrameSize).currentSize
  const { setDisplayPreview, setScenes, setCurrentDesign, setCurrentScene, currentDesign, currentScene, scenes } =
    useDesignEditorContext()

  const flatPdf = async () => {
    if (!isOwner) {
      alert("To download pdf, one needs to purchase the Coloring Book Maker tool first.")
      return
    }
    dispatch(
      setLoadingStatus({
        isLoading: true,
        title: "Preparing PDF...",
      })
    )

    editor.frame.frame.canvas.isDrawingMode = false
    editor.cancelContextMenuRequest()
    const currentScene = editor.scene.exportToJSON()

    const pdfDoc = new jsPDF({
      unit: "in",
      format: [currentSize.width, currentSize.height],
    })

    // pdf-lib uses inches as units (1 inch = 72 points) (1 inch = 96px) (1pt = 1.3333333333333333px) default unit is pt

    for (let i = 0; i < scenes.length; i++) {
      const scn = scenes[i]
      if (i === 0) {
        // add first page
        if (scn.id === currentScene.id) {
          const preview = await editor.renderer.toDataURL(currentScene, {})

          // add new page
          pdfDoc.addImage(
            preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        } else {
          // add new page
          pdfDoc.addImage(
            scn.preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        }
      } else {
        pdfDoc.addPage()
        if (scn.id === currentScene.id) {
          const preview = await editor.renderer.toDataURL(currentScene, {})

          pdfDoc.addImage(
            preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        } else {
          pdfDoc.addImage(
            scn.preview as string,
            0,
            0,
            pdfDoc.internal.pageSize.getWidth(),
            pdfDoc.internal.pageSize.getHeight(),
            undefined,
            "FAST"
          )
        }
      }
    }

    if (currentDesign) {
      pdfDoc.save(`${currentDesign.name}.pdf`)
      // editor.cancelContextMenuRequest()
      // editor.canvas.requestRenderAll()
    } else {
      console.info("NO CURRENT DESIGN")
    }
    dispatch(
      setLoadingStatus({
        isLoading: false,
        title: "",
      })
    )
  }

  const PANEL_ITEMS = BASE_ITEMS
  return (
    <Container className="bg-[#D14DED]">
      <Scrollable autoHide={true}>
        {PANEL_ITEMS.map((panelListItem) => (
          <PanelListItem
            label={t(`panels.panelsList.${panelListItem.id}`)}
            name={panelListItem.name}
            key={panelListItem.name}
            icon={panelListItem.name}
            activePanel={activePanel}
          />
        ))}
        <div className="mt-2">
          {isOwner ? (
            <Button
              style={{ fontSize: "13px", border: "none", background: "none", color: "white" }}
              onClick={flatPdf}
              className="flex flex-col items-center  justify-center cursor-pointer "
            >
              <DownloadIcon className="text-white" />
              Download
            </Button>
          ) : (
            <Button
              onClick={() => {
                window.open("http://www.selfpublishingtitans.com/coloring-book-maker", "_self")
              }}
            >
              Buy Now
            </Button>
          )}
        </div>
        <div
          onClick={() =>
            window.open(
              "https://docs.google.com/document/d/13vFaJ7YousbC6Zy77xcVY2VgSCVhhr3dI1cpKntycro/edit",
              "_blank"
            )
          }
          className="flex justify-center flex-col mt-3 items-center"
        >
          <LibraryBooksIcon className="text-white" />
          <Button>
            <Typography fontSize={13} className="text-white">
              How to use
            </Typography>
          </Button>
        </div>
      </Scrollable>
    </Container>
  )
}

const PanelListItem = ({ label, icon, activePanel, name }: any) => {
  const { setActivePanel } = useAppContext()
  const setIsSidebarOpen = useSetIsSidebarOpen()
  const [css, theme] = useStyletron()
  // @ts-ignore
  const Icon = Icons[icon]
  return (
    <Block
      id="EditorPanelList"
      onClick={() => {
        setIsSidebarOpen(true)
        setActivePanel(name)
      }}
      $style={{
        width: "80px",
        height: "65px",
        backgroundColor: name === activePanel ? "#984BF5" : "#D14DED",
        color: theme.colors.white,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        fontFamily: "Uber Move Text",
        fontWeight: 500,
        fontSize: "0.8rem",
        userSelect: "none",
        transition: "all 0.5s",
        gap: "0.1rem",
        ":hover": {
          cursor: "pointer",
          backgroundColor: "#944BF7",
          transition: "all 1s",
        },
      }}
    >
      <Icon size={24} />
      <div>{label}</div>
    </Block>
  )
}

export default PanelsList
