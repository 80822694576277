import { uploadedFontsClassDB } from "./../../../indexDB/db"
import { IFontFamily } from "~/interfaces/editor"
import { createAsyncThunk, createAction } from "@reduxjs/toolkit"
import api from "~/services/api"
import { orderBy } from "lodash"
import { listFiles, upload } from "~/services/cloud-service"
import { getFontMeta } from "~/utils/fonts"
import { nanoid } from "nanoid"

interface QueryFont {
  take: number
  skip: number
  query: string
}

// url to font file
  const fileUrlToFile = async (url: string) => {
    const response = await fetch(url)
    const data = await response.blob()
    const metadata = {
      type: "application/octet-stream",
    }
    return new File([data], "font", metadata)
  }

export const getCloudFonts = async () => {
  const token = sessionStorage.getItem("token")
  if (!token) return []
  const baseUrl = import.meta.env.VITE_R2_COLORING_BOOKS_FONT_URL
  const allFonts = await listFiles("coloring-books-fonts", token)
      if (allFonts?.data?.data?.length ?? 0) {
      let fonts = []
      for(let i = 0; i < allFonts.data.data.length; i++) {
        const file = allFonts.data.data[i]
        const url = `${baseUrl}/${file.file_path}`
        const fontFile = await fileUrlToFile(url)
        const fontMeta: any = await getFontMeta(fontFile)
        fonts.push({
          id: nanoid(),
          name: fontMeta.fontFamily,
          family: fontMeta.fontFamily,
          full_name: fontMeta.fullName,
          post_script_name: fontMeta.postScriptName,
          preview: "",
          style: "normal",
          // fontFamily: fontMeta.fontFamily,
          url: url,
          category: "none",
        })
      }

      return fonts
    }
    return []
}

export const setFonts = createAction<IFontFamily[]>("fonts/setFonts")

export const queryFonts = createAction<QueryFont>("fonts/queryFonts")

export const getFonts = createAsyncThunk<void, never, { rejectValue: Record<string, string[]> }>(
  "fonts/getFonts",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      let fonts = await api.getFonts()
      
      const uploadedFonts = await uploadedFontsClassDB.fonts.get(1)
      let cloudFonts = await getCloudFonts()
      if (cloudFonts) {
        fonts = [...fonts, ...cloudFonts]
      }
      if (uploadedFonts) {
        dispatch(setFonts(orderBy([...fonts, ...uploadedFonts.fonts], ["family"], ["asc"])))
      } else {
        dispatch(setFonts(orderBy(fonts, ["family"], ["asc"])))
      }
    } catch (err) {
      return rejectWithValue((err as any).response?.data?.error.data || null)
    }
  }
)
