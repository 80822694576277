function CloudCheck({ size }: { size: number }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 24 24">
      <path
        d="M21 14.4822C20.9982 16.0732 20.3654 17.5985 19.2404 18.7234C18.1155 19.8484 16.5902 20.4812 14.9992 20.483H14.8215L16.6329 18.6715C17.5626 18.3104 18.3458 17.6504 18.8592 16.7953C19.3725 15.9401 19.5869 14.9386 19.4685 13.9483C19.3501 12.958 18.9056 12.0352 18.2051 11.3252C17.5047 10.6152 16.5879 10.1584 15.5993 10.0267L15.0307 9.95165L14.9557 9.38308C14.7981 8.25672 14.2199 7.23181 13.3373 6.51444C12.4547 5.79707 11.3333 5.44045 10.1985 5.51628C9.0637 5.59211 7.99969 6.09477 7.22042 6.92319C6.44116 7.75161 6.00443 8.84434 5.99807 9.98166C5.9972 10.9449 6.30825 11.8825 6.88468 12.6543L7.54777 13.5484L6.46913 13.8267C5.85012 13.9843 5.31008 14.3628 4.95066 14.8908C4.59124 15.4189 4.43723 16.0601 4.51761 16.6938C4.598 17.3275 4.90724 17.9099 5.38713 18.3315C5.86701 18.7531 6.48445 18.9847 7.12321 18.9828H7.21397L8.71417 20.483H7.12321C6.20508 20.4842 5.3129 20.1785 4.58841 19.6145C3.86393 19.0505 3.3487 18.2606 3.12458 17.3702C2.90046 16.4799 2.98031 15.5401 3.35144 14.7004C3.72257 13.8606 4.3637 13.1689 5.17296 12.7353C4.72878 11.8854 4.49714 10.9406 4.49787 9.98166C4.50256 8.51056 5.04748 7.09243 6.02909 5.99671C7.0107 4.90099 8.36061 4.20403 9.82235 4.03824C11.2841 3.87245 12.7558 4.24939 13.9579 5.09744C15.1599 5.94548 16.0086 7.20555 16.3427 8.63823C17.664 8.94479 18.8431 9.68883 19.6885 10.7496C20.5339 11.8104 20.996 13.1258 21 14.4822V14.4822ZM13.3588 19.8237L17.5998 15.5826L16.5392 14.522L12.2989 18.763C12.1561 18.8996 11.9661 18.9758 11.7686 18.9758C11.571 18.9758 11.381 18.8996 11.2382 18.763L8.69842 16.2247L7.63853 17.2853L10.1769 19.8237C10.5988 20.2455 11.1711 20.4825 11.7678 20.4825C12.3645 20.4825 12.9368 20.2455 13.3588 19.8237V19.8237Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default CloudCheck
