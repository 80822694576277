import Navbar from "./components/Navbar"
import Panels from "./components/Panels"
import Canvas from "./components/Canvas"
import Footer from "./components/Footer"
import Toolbox from "./components/Toolbox"
import EditorContainer from "./components/EditorContainer"
import ContextMenu from "./components/ContextMenu"
import { useEffect, useState } from "react"
import { checkOwnershipByID } from "~/services/chekcProduct"
import { useSelector } from "react-redux"
import { isOwnerSelector } from "~/store/slices/auth/selectors"

const GraphicEditor = () => {
  const [isLoading, setIsLoading] = useState(true)
  const checkToken = () => {
    const token = sessionStorage.getItem("token")
    if (!token) {
      window.location.href = "/login"
    }

    setIsLoading(false)
  }
  useEffect(() => {
    checkToken()
  }, [])

  if (isLoading) return <div>Loading...</div>

  return (
    <EditorContainer>
      <Navbar />
      <div style={{ display: "flex", flex: 1 }}>
        <Panels />
        <div style={{ flex: 1, display: "flex", flexDirection: "column", position: "relative" }}>
          <Toolbox />
          <Canvas />
          <Footer />
        </div>
      </div>
    </EditorContainer>
  )
}

export default GraphicEditor
