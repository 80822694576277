import TextProperties from "./TextProperties"
import Text from "./Text"
import PageSizes from "./PageSizes"
import FontSelector from "./FontSelector"
import SearchComponent from "./Search"
import Categories from "./Categories"
import Uploads from "./Uploads"
import Images from "./Images"
import Videos from "./Videos"
import Layers from "./Layers"
import Elements from "./Elements"
import Graphics from "./Graphics"
import TextFill from "./TextFill"
import PathFill from "./PathFill"
import CanvasFill from "./CanvasFill"
import TextEffects from "./TextEffects"
import Download from "./Download"
import Draw from "./Draw"
import Eraser from "./Eraser"
export default {
  StaticText: TextProperties,
  Text: Text,
  PageSizes: PageSizes,
  FontSelector: FontSelector,
  Search: SearchComponent,
  Categories: Categories,
  Uploads: Uploads,
  Images: Images,
  Videos: Videos,
  Elements: Elements,
  Graphics: Graphics,
  Layers: Layers,
  TextFill: TextFill,
  PathFill: PathFill,
  CanvasFill: CanvasFill,
  TextEffects: TextEffects,
  Draw: Draw,
  Download: Download,
  // Eraser: Eraser,
}
