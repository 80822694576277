import { useEditor } from "@layerhub-io/react"
import { Add, CloudUploadOutlined, History, CloudSyncOutlined } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { authSelector } from "~/store/slices/auth/selectors"
import { selectedProject } from "~/store/slices/project/selectors"
import { syncDesignData } from "~/views/DesignEditor/components/Navbar/DesignTitle"
import { CreateProjectModal, NavLink, OpenRecentFileModal } from "~/views/DesignEditor/components/Navbar/Navbar"

function FileComponent() {
  const [isCreateNewProject, setIsCreateNewProject] = useState(false)
  const [isRecentFileOpen, setIsRecentFileOpen] = useState(false)
  const token = useSelector(authSelector).token

  const [isSaving, setIsSaving] = useState(false)

  const syncData = syncDesignData(setIsSaving);

  // on click outside close the dropdown
//   useEffect(() => {
//     const handleClick = (e: any) => {
//       console.log("Clicked", e.target.id)
//       if (e.target.id !== "file" && e.target.id !== "dropdownButton") {
//         console.log("Clicked outside")
//         // setIsFileOpen(false)
//       }
//     }
//     document.addEventListener("click", handleClick)
//     return () => {
//       document.removeEventListener("click", handleClick)
//     }
//   }, [])

  return (
    <div
      id="file"
      style={{
        boxShadow: "2px 2px 20px 2px rgba(0, 0, 0, 0.3), 0 4px 6px -2px rgba(0, 0, 0, 0.2)",
      }}
      className={`animate__animated animate__fadeIn animate__faster rounded-lg 
  fixed top-10 left-20 transform -translate-x-1/2 mt-2 br-16  max-w-[75w] z-50 text-base list-none bg-white divide-y divide-secCol1-50 lg:shadow`}
    >
      {isCreateNewProject && <CreateProjectModal token={token} setIsOpen={setIsCreateNewProject} />}
      {isRecentFileOpen && <OpenRecentFileModal token={token} setIsOpen={setIsRecentFileOpen} />}
      <ul className="py-1 pl-8 lg:pl-0 rounded-lg" aria-labelledby="dropdownButton">
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsCreateNewProject(true)
          }}
          className=""
        >
          <NavLink name="New" href="#" icon={<Add />} />
        </div>
        {/* <div
          onClick={(e) => {
            e.stopPropagation()
          }}
          className=""
        >
          <NavLink name="Open" href="#" icon={<CloudUploadOutlined />} />
        </div> */}
        <div
          onClick={(e) => {
            e.stopPropagation()
            setIsRecentFileOpen(true)
          }}
          className=""
        >
          {/* Open Recent File */}
          <NavLink name="Open Recent" href="#" icon={<History />} />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation()
            syncData()
          }}
          className=""
        >
          <NavLink name="Save" icon={<CloudSyncOutlined />} />
        </div>
        {/* <div
          onClick={(e) => {
            e.stopPropagation()
            
          }}
          className=""
        >
          <NavLink name="Save As" href="#" icon={<SaveAsOutlined />} />
        </div> */}
      </ul>
    </div>
  )
}

export default FileComponent