import axios from "axios";


const api = axios.create({
    baseURL: import.meta.env.VITE_GO_SERVER_URL,
    })


// upload
// /api/v1/cloud/upload
// token required
// form-data: file, bucket
// Method: POST
// response:
// "data": {
//         "file_path": "63cd2f80f739d31d4d7f9ac4/6645b3c7aceecf54d053508e3ba3eb7e-7fbc-445f-a266-77cb8107c4a3.png"
//     },
//     "message": "File uploaded successfully"
// }
export const upload = (file: File, bucket: string, token: string) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("bucket", bucket)

    return api.post("/api/v1/cloud/upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
        }
    })
}

// v1/cloud/download
// {
//     "file_path": "63cd2f80f739d314d7f9ac4/6639cfd5757006cafc15c53ce49c034e-5e59-4c9b-9b0b-bd547ae0c272.png",
//     "bucket": "puzzle"
// }
// token required
export const download = (file_path: string, bucket: string, token: string) => {
    return api.post("/api/v1/cloud/download", {
        file_path,
        bucket
    }, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

// /api/v1/cloud/delete
// {
//     "file_path": "63cd2f80f739d31d4d7f9ac4/6639c8d7df08ced159b3f4a46c64a1dc-80ec-46eb-a352-6e6b4f11275e.png",
//     "bucket": "puzzle"
// }
// token required
// Method: DELETE
export const deleteFile = (file_path: string, bucket: string, token: string) => {
    return api.delete("/api/v1/cloud/delete", {
        data: {
            file_path,
            bucket
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}

// api/v1/cloud/list?bucket=coloring-books
// without the bucket query param, it will return all the files in the bucket by user
// token required
// Method: GET
// response: 
// {
//     "data": [
//         {
//             "id": "6645b3caaceecf54d053508f",
//             "user": "63cd2f80f739d31d4d7f9ac4",
//             "file_path": "63cd2f80f739d31d4d7f9ac4/6645b3c7aceecf54d053508e3ba3eb7e-7fbc-445f-a266-77cb8107c4a3.png",
//             "bucket": "coloring-books",
//             "file_size": 281974,
//             "content_type": "image/png",
//             "created_at": "2024-05-16T07:20:42.427Z",
//             "updated_at": "2024-05-16T07:20:42.427Z"
//         }
//     ]
// }
export const listFiles = (bucket: string, token: string) => {
    return api.get("/api/v1/cloud/list", {
        params: {
            bucket
        },
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}


// api/v1/cloud/usage
// token required

// Method: GET
// response:
// {
//     "data": {
//         "used": 1940624,
//         "total": 2147483648
//     }
// }

export const usage = (token: string) => {
    return api.get("/api/v1/cloud/usage", {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
}
