function Presentation({ size }: { size: number }) {
  return (
    <svg height={size} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 15V13.5H19.5V5.25C19.5 4.65326 19.2629 4.08097 18.841 3.65901C18.419 3.23705 17.8467 3 17.25 3H6.75C6.15326 3 5.58097 3.23705 5.15901 3.65901C4.73705 4.08097 4.5 4.65326 4.5 5.25V13.5H3V15H11.25V18H9.75C9.15326 18 8.58097 18.2371 8.15901 18.659C7.73705 19.081 7.5 19.6533 7.5 20.25V21H9V20.25C9 20.0511 9.07902 19.8603 9.21967 19.7197C9.36032 19.579 9.55109 19.5 9.75 19.5H14.25C14.4489 19.5 14.6397 19.579 14.7803 19.7197C14.921 19.8603 15 20.0511 15 20.25V21H16.5V20.25C16.5 19.6533 16.2629 19.081 15.841 18.659C15.419 18.2371 14.8467 18 14.25 18H12.75V15H21ZM6 5.25C6 5.05109 6.07902 4.86032 6.21967 4.71967C6.36032 4.57902 6.55109 4.5 6.75 4.5H17.25C17.4489 4.5 17.6397 4.57902 17.7803 4.71967C17.921 4.86032 18 5.05109 18 5.25V13.5H6V5.25Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Presentation
