import { useEffect } from "react"
import useOneTapSignin from "./useOneTapSignin"

const OneTapComponent = () => {
  const { isLoading: oneTapIsLoading } = useOneTapSignin(
    {
      parentContainerId: "oneTap",
    },
    2000
  )

  console.log("One Tap is loading ::", oneTapIsLoading)

  useEffect(() => {
    // Dynamically create the script tag for Google One-Tap
    const script = document.createElement("script")
    script.src = "https://accounts.google.com/gsi/client"
    script.async = true
    script.defer = true

    // Append the script tag to the head of the document
    document.head.appendChild(script)

    // Cleanup the script tag when the component unmounts
    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <div>
      <div id="oneTap" className="fixed top-0 right-0 " />
    </div>
  ) // This is done with tailwind. Update with system of choice
}

export default OneTapComponent
