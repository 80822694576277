// db.ts
import Dexie, { Table } from "dexie"

export interface Scenes {
  id?: number
  scenes: any[]
  currentScenes: any
}

const version = 1

export class ScenesClassDB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  scenes!: Table<Scenes>

  constructor() {
    super("scenesTable")
    this.version(version).stores({
      scenes: "++id, scenes, currentScenes", // Primary key and indexed props
    })
  }
}

export const scenesClassDB = new ScenesClassDB()

export interface UploadedImages {
  id?: number
  images: any[]
  createdAt: Date
}

export class UploadedImagesClassDB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  images!: Table<UploadedImages>

  constructor() {
    super("imagesTable")
    this.version(version).stores({
      images: "++id, images, createdAt", // Primary key and indexed props
    })
  }
}

export const uploadedImagesClassDB = new UploadedImagesClassDB()

export interface IUploadedFonts {
  id?: number
  fonts: any[]
  createdAt: Date
}
export class UploadedFontsClassDB extends Dexie {
  // 'friends' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  fonts!: Table<IUploadedFonts>

  constructor() {
    super("fontsTable")
    this.version(version).stores({
      fonts: "++id, fonts, createdAt", // Primary key and indexed props
    })
  }
}

export const uploadedFontsClassDB = new UploadedFontsClassDB()
