import React from "react"

const ArrowDown = ({ size }: { size: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width="24px" fill="#000">
      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  )
}

export default ArrowDown
