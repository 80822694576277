import React from "react"

const ArrowDoubleUp = ({ size }: { size: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width="24px" fill="#00">
      <path d="m296-224-56-56 240-240 240 240-56 56-184-183-184 183Zm0-240-56-56 240-240 240 240-56 56-184-183-184 183Z" />
    </svg>
  )
}

export default ArrowDoubleUp
