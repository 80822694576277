// Define a type for the slice state
export interface ILoadingStatusState {
  isLoading: boolean
  title?: string
}
// snackbar
// export const snackBarSlice = createSlice({
//   name: "snackBar",
//   initialState,
//   reducers: {
//     openSnackBar: (state, action: PayloadAction<ISnackBarState>) => {
//       state.isOpen = true
//       state.title = action.payload.title
//       state.message = action.payload.message
//       state.KIND = action.payload.KIND
//       state.timeout = action.payload.timeout
//     },
//     closeSnackBar: (state) => {
//       state.isOpen = false
//       state.title = ""
//       state.message = ""
//       state.KIND = undefined
//     },
//   },
// })

// export default snackBarSlice.reducer

import { createAction } from "@reduxjs/toolkit"

export const setLoadingStatus = createAction<ILoadingStatusState>("loading/setLoadingStatus")
