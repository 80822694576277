import { useEffect, useState } from "react"
import ComBG from "../../components/common/Bg"
import ComButton from "../../components/common/Button1"
import ComInput from "../../components/common/Input"
import Logo, { NewLogo } from "~/components/Icons/Logo"
import { ILoginResponse, login } from "~/services/auth"
import Brandlogo from "../../../public/assets/logos/brand_logo_purple.png"
import { Button, TextField, Typography, Link, Alert } from "@mui/material"
import OneTapComponent from "~/components/Auth/oneTapComponent"

function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<any>("")
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  async function loginUser() {
    setLoading(true)
    setError(false)

    if (loading) {
      return false
    }
    try {
      let result: ILoginResponse = await login({ username: email, password })
      // // console.log(result.data.error.full)
      if (result.token) {
        sessionStorage.setItem("token", result.token)
        window.location.href = "/"
      } else {
        setShowError(true)
        if (result && result.Error) {
          setError(result.Error)
        } else {
          setError("Something went wrong")
        }
      }
    } catch (e: any) {
      console.error(e)
      setError(e.message)
    }
    setLoading(false)
  }

  return (
    <div>
      <OneTapComponent />
      <div className="fixed w-screen h-screen overflow-hidden">
        <ComBG className="w-screen" />
      </div>

      <main className=" relative w-screen h-screen flex flex-col items-center">
        <div className="relative  flex flex-col rounded-xl bg-white p-5 md:rounded-xl w-full sm:w-1/2 lg:w-1/3 my-auto py-14 transform scale-90 shadow-lg">
          {error && (
            <Alert
              severity="error"
              className={`${showError ? "block" : "hidden"} text-center mt-5 text-primCol1-700 text-base`}
            >
              {error}
            </Alert>
          )}
          <h1 className="text-5xl text-center mx-auto mb-5">
            <img src={Brandlogo} alt="Brand Logo" className="h-auto w-24 mx-auto" />
          </h1>

          {/* <ComInput icon={"user"} label="Email" value={email} setValue={setEmail} /> */}
          <Typography variant="h5" textAlign={"center"}>
            Login To Self Publishing Titans
          </Typography>
          <div className="w-full mt-5">
            <TextField
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="h-2" />

          <TextField
            type="password"
            margin="dense"
            placeholder="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="h-2" />
          <Button variant="contained" onClick={() => loginUser()}>
            {loading ? <div className="h-3 w-3 bg-primCol1-100 animate-bounce mx-auto m-1" /> : "Login"}
          </Button>
          <div className="flex mt-5 justify-between mb-10 w-full">
            <div className="flex  justify-center">
              <Link href={`https://www.selfpublishingtitans.com/auth/forgot`} className="text-sm text-secCol1-700">
                <Typography variant="body2">Forgot Password?</Typography>
              </Link>
            </div>
            <br />
            <Link href="https://www.selfpublishingtitans.com/auth/register" className="text-primary text-center">
              <Typography variant="body2">Don't have an account? Sign Up</Typography>
            </Link>
          </div>

          <br />
          <Typography variant="body2" className="text-sm mt-10 text-center text-gray-700  mb-5">
            All Rights Reserved 2021
          </Typography>
        </div>
      </main>
    </div>
  )
}

export default Login
