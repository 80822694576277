import React from "react"

const ArrowUp = ({ size }: { size: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width="24px" fill="#00">
      <path d="M480-528 296-344l-56-56 240-240 240 240-56 56-184-184Z" />
    </svg>
  )
}

export default ArrowUp
