import { Typography } from "@mui/material"
import { sections, SectionItem, Section } from "./data"
import { Link } from "@mui/material"
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material"
import { Logout as LogoutIcon } from "@mui/icons-material"

const BOOKS_SUBDOMAIN = "https://books.selfpublishingtitans.com"
const MAIN_DOMAIN = "https://www.selfpublishingtitans.com"

function AccountDropdown({ logoutFunc }: any) {
  return (
    <div className={` h-fit br-16 rounded-md w-fit `}>
      <div className="w-full flex flex-row  ">
        <div className=" flex br-16 flex-row justify-evenly">
          <div
            // hidden={!isAccountDropdown}
            // onMouseLeave={() => setIsAccountDropdown(false)}
            // onMouseEnter={() => setIsAccountDropdown(true)}
            className=" right-0 border-2 z-10 mt-0 w-56 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            role="menu"
            style={{ border: "1px solid #cccccc" }}
            aria-orientation="vertical"
            aria-labelledby="menu-button"
          >
            <div className="py-1" role="none">
              <div className="flex  my-0 items-center">
                <AccountCircleIcon className="mx-2" color="primary" />
                <Link
                  underline="none"
                  target={"_blank"}
                  href={MAIN_DOMAIN + "/auth/me"}
                  className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                  role="menuitem"
                  id="menu-item-0"
                >
                  My Account
                </Link>
              </div>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/titans-retro-vision"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-5"
              >
                Titans Retro View
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/titans-deep-view"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-6"
              >
                Titans Deep View
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href="https://design.selfpublishingtitans.com"
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-7"
              >
                Digital Titans Designer
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/titans-keyword-tracker"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-8"
              >
                Titans Keyword Tracker
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/titans-asin-tracker"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-8"
              >
                Titans Product Tracker
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/titans-pro/on-site"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-0"
              >
                Titans Pro Web App
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={`${BOOKS_SUBDOMAIN}/Puzzle-Maker-Software`}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-1"
              >
                Create Puzzle & Activities
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={`${BOOKS_SUBDOMAIN}/Activity-Book-Creator-Software/start-now?simple=true`}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-2"
              >
                Create Puzzle Pages
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={`https://editor.selfpublishingtitans.com`}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-3"
              >
                Create Coloring Pages
              </Link>
              <hr />
              <Link
                underline="none"
                target={"_blank"}
                href={MAIN_DOMAIN + "/masterclass/courses/63149a704f08614dd053ec3d"}
                className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100"
                role="menuitem"
                id="menu-item-4"
              >
                KDP Masterclass
              </Link>
              <hr />
              <div className="flex items-center my-0  cursor-pointer">
                <LogoutIcon className="mx-2 ml-3 " color="primary" />
                <p
                  className="block text-[15px] text-secCol1-700 ml-4 bg-white p-2 rounded-md border border-secCol1-500"
                  onClick={() => {
                    logoutFunc()
                    // ChromeExtensionLogout()
                    // newChromeExtensionLogout()
                  }}
                >
                  Logout
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountDropdown
