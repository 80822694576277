import { createReducer } from "@reduxjs/toolkit"
// import { IAuthenticationState, IIsOwner, setAuth, setOwner } from "./actions"
import {  IProjectState, setProject } from "./actions"

// const initialState: IAuthenticationState = {
//   isAuthenticated: false,
//   user: null,
//   token: null,
// }

const initialState: IProjectState = {
  _id: "",
  id: "",
  owner: "",
  project_name: "",
  project_settings: {},
  is_selected: false,
  last_updated_at: "",
  createdAt: "",
  updatedAt: "",
}

// const isOwnerInitialState: IIsOwner = {
//   isOwner: false,
//   isDirty: false,
// }

// export const authReducer = createReducer(initialState, (builder) => {
//   builder.addCase(setAuth, (state) => {
//     const token = sessionStorage.getItem("token")
//     state.token = token
//     if (token) {
//       state.isAuthenticated = true
//     }
//   })
// })

export const projectReducer = createReducer(initialState, (builder) => {
  builder.addCase(setProject, (state, { payload }) => {
    state._id = payload._id
    state.id = payload.id
    state.owner = payload.owner
    state.project_name = payload.project_name
    state.project_settings = payload.project_settings
    state.is_selected = payload.is_selected
    state.last_updated_at = payload.last_updated_at
    state.createdAt = payload.createdAt
    state.updatedAt = payload.updatedAt
  })
})

// export const isOwnerReducer = createReducer(isOwnerInitialState, (builder) => {
//   builder.addCase(setOwner, (state, { payload }) => {
//     state.isOwner = payload.isOwner
//     state.isDirty = payload.isDirty
//   })
// })

// export default projectReducer
