import React from "react"

const ArrowDoubleDown = ({ size }: { size: number }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 -960 960 960" width="24px" fill="#000">
      <path d="M480-200 240-440l56-56 184 183 184-183 56 56-240 240Zm0-240L240-680l56-56 184 183 184-183 56 56-240 240Z" />
    </svg>
  )
}

export default ArrowDoubleDown
